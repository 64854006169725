import React, { useState } from 'react'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { light, dark } from '@pancakeswap-libs/uikit'

const CACHE_KEY = 'IS_DARK'

const ThemeContext = React.createContext({ isDark: null, toggleTheme: () => null })

const ThemeContextProvider = ({ children }) => {
  dark.colors.primary = '#92c855'
  dark.colors.secondary = 'white'
  dark.colors.background = 'black'
  dark.button.primary.background = 'white'
  dark.button.primary.backgroundHover = 'white'
  dark.button.primary.color = 'black'
  dark.colors.text = 'white'
  dark.colors.textSubtle = 'whitesmoke'
  dark.colors.input = 'gray'
  dark.colors.success = '#92c855'
  dark.nav.background = 'black'

  light.colors.primary = '#92c855'
  light.colors.secondary = 'black'
  light.colors.background = 'whitesmoke'
  light.button.primary.background = 'black'
  light.button.primary.backgroundHover = 'black'
  light.button.primary.color = 'white'
  light.colors.text = 'black'
  light.colors.textSubtle = 'black'
  light.colors.input = 'whitesmoke'
  light.colors.success = '#92c855'
  light.nav.background = 'white'

  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY)
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : true
  })

  const toggleTheme = () => {
    setIsDark((prevState) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState))
      return !prevState
    })
  }

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider theme={isDark ? dark : light}>{children}</SCThemeProvider>
    </ThemeContext.Provider>
  )
}

export { ThemeContext, ThemeContextProvider }
