import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xdDa8B8e973060215d1aEe3839189cD42eAea8230',
      },
      {
        label: 'Liquidity',
        href: 'https://pancakeswap.finance/add/BNB/0xdDa8B8e973060215d1aEe3839189cD42eAea8230',
      }
      // {
      //   label: 'Bridge',
      //   href: 'https://exchange.ourglass.io/#/bridge',
      // },
    ],
  },
  // {
  //   label: 'Forge',
  //   icon: 'FarmIcon',
  //   href: '/forge',
  // },
  {
    label: 'Ultra',
    icon: 'PoolIcon',
    href: '/ultra',
  },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/game',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Chart',
        href: 'https://dex.guru/token/0xdDa8B8e973060215d1aEe3839189cD42eAea8230-bsc'
      },
      {
        label: 'White Paper',
        href: 'https://ourglass2021.gitbook.io/our-glass/',
      }
      // {
      //   label: 'CoinMarketCap',
      //   href: 'https://coinmarketcap.com/currencies/ourglass/',
      // },
      // {
      //   label: 'CoinGecko',
      //   href: 'https://www.coingecko.com/en/coins/ourglass',
      // },
    ],
  },
  // {
  //   label: 'More',
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: 'White Paper',
  //       href: 'https://ourglass2021.gitbook.io/our-glass/',
  //     },
  //   ],
  // },
  // {
  //   label: 'Partnerships/IFO',
  //   icon: 'GooseIcon',
  //   href: 'https://docs.google.com/forms/d/e/1FAIpQLSe7ycrw8Dq4C5Vjc9WNlRtTxEhFDB1Ny6jlAByZ2Y6qBo7SKg/viewform?usp=sf_link',
  // },
  {
    label: 'Audit',
    icon: 'AuditIcon',
    href: 'https://solidity.finance/audits/OurGlass/',
  },
  // {
  //   label: 'Audit by CertiK',
  //   icon: 'AuditIcon',
  //   href: 'https://certik.org/projects/goose-finance',
  // },
]

export default config
