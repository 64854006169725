export default {
  glass: {
    56: '0xdDa8B8e973060215d1aEe3839189cD42eAea8230',
    97: '0x67BA81722f5c8cbCd1CFB89BC93d8b184232a5cA',
  },
  masterChef: {
    56: '0x57987c2C64DacD347dbCa6949aa3597fE39110A6',
    97: '0x95bCe5bDee12612E5B84DD76e39F18f592670970',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '0x094616f0bdfb0b526bd735bf66eca0ad254ca81f',
  },
  stake: {
    56: '0xadEa01c3d890852d90255DAa42Dcf4fD2826e33F',
    97: '0xBE65Db871Da5527F63a673D547A0D96aaA0F66B1',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '0x8301f2213c0eed49a7e28ae4c3e91722919b8b47',
  },
}
