import BigNumber from 'bignumber.js'

export const getBalanceNumber = (balance: BigNumber, decimals = 9) => {
  const displayBalance = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals))
  return displayBalance.toNumber()
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 9) => {
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed()
}

export const formatBalance = (
  num: number,
  formatMillions = false,
  abbreviationFormats = [
    { value: 1e18, symbol: 'Q' },
    { value: 1e15, symbol: 'Q' },
    { value: 1e12, symbol: 'T' },
    { value: 1e9, symbol: 'B' },
    // { value: 1e6, symbol: 'M' },
    // { value: 1e3, symbol: 'K' },
  ],
): string | number => {
  if (num == null || typeof num !== 'number') {
    // null, undefined, non-numeric, return what was provided
    return num
  }
  if (formatMillions) {
    abbreviationFormats.push({ value: 1e6, symbol: 'M' })
  }

  let format
  if (abbreviationFormats != null) {
    // formats were provided, find one that works
    format = abbreviationFormats.find((f) => num >= f.value)
  }

  if (format != null) {
    // apply the format, insert the symbol next to the numeric portion of the formatted string
    const { value, symbol } = format
    const formatted = (num / value).toLocaleString()
    const parts = formatted.match(/([\D]*)([\d.,]+)([\D]*)/)
    return `${parts[1]}${parts[2]}${symbol}${parts[3]}`
  }

  // otherwise, use the number as provided
  return num.toLocaleString(undefined, { maximumFractionDigits: 0 })
}
