/* eslint-disable no-await-in-loop */
import BigNumber from 'bignumber.js'
import {
  Interface
} from '@ethersproject/abi'
import {
  getWeb3
} from 'utils/web3'
import MultiCallAbi from 'config/abi/Multicall.json'
import {
  getMulticallAddress
} from './addressHelpers'

export const multiCall = async (abi, calls) => {
  const web3 = getWeb3()
  const multi = new web3.eth.Contract(MultiCallAbi, getMulticallAddress())
  const itf = new Interface(abi)
  let res = []
  if (calls.length > 100) {
    let i = 0
    while (i < calls.length / 100) {
      const newCalls = calls.slice(i * 100, 100 * (i + 1))
      const calldata = newCalls.map((call) => [call[0].toLowerCase(), itf.encodeFunctionData(call[1], call[2])])
      const {
        returnData
      } = await multi.methods.aggregate(calldata).call()
      i++
      res = res.concat(returnData.map((call, index) => itf.decodeFunctionResult(newCalls[index][1], call)))
    }
  } else {
    const calldata = calls.map((call) => [call[0].toLowerCase(), itf.encodeFunctionData(call[1], call[2])])
    const {
      returnData
    } = await multi.methods.aggregate(calldata).call()
    res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i][1], call))
  }
  return res
}

export const deposit = async (lotteryContract, price, account) => {
  try {
    const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
    const REF_KEY = 'REF_KEY'
    let ref = new RegExp('[?&]ref=([^&#]*)').exec(window.location.search);
    ref = ref && ref.length > 1 ? ref[1] : null

    if (ref === null || ref === undefined) {
      ref = localStorage.getItem(REF_KEY)

      if (ref === null || ref === undefined) {
        ref = ZERO_ADDRESS
      }
    }

    if (ref && ref === account) {
      if (localStorage.getItem(REF_KEY)) localStorage.removeItem(REF_KEY)
      return alert('Cannot refer yourself')
    }

    return lotteryContract.methods
      .deposit(new BigNumber(price).times(new BigNumber(10).pow(9)).toString(), ref)
      .send({
        from: account
      })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
      .on('receipt', (receipt) => {
        if (ref !== ZERO_ADDRESS) localStorage.setItem(REF_KEY, ref)
      })
      .on('error', (error) => { 
        console.error(error)
      })
  } catch (err) {
    return console.error(err)
  }
}

export const claim = async (lotteryContract, account) => {
  try {
    return lotteryContract.methods
      .claim()
      .send({
        from: account
      })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  } catch (err) {
    return console.error(err)
  }
}

export const compound = async (lotteryContract, account) => {
  try {
    return lotteryContract.methods
      .roll()
      .send({
        from: account
      })
      .on('transactionHash', (tx) => {
        return tx.transactionHash
      })
  } catch (err) {
    return console.error(err)
  }
}

export const getTotalRewards = async (lotteryContract, account) => {
  return lotteryContract.methods.getTotalRewards(account).call()
}

export const getGlassBalance = async (glassContract, account) => {
  return glassContract.methods.balanceOf(account).call()
}

export const getGlassBalancePool = async (lotteryContract) => {
  return lotteryContract.methods.getGlassBalancePool().call()
}

export const getTotalDeposited = async (lotteryContract) => {
  return lotteryContract.methods.total_deposited().call()
}

export const getLargestDayDepositor = async (lotteryContract) => {
  return lotteryContract.methods.getLargestDepositor().call()
}

export const getTimeToReward = async (lotteryContract) => {
  return lotteryContract.methods.timeToReward().call()
}

export const getLargestTime = async (lotteryContract) => {
  return lotteryContract.methods.largestTime().call()
}

export const getLargestTimeIncrement = async (lotteryContract) => {
  return lotteryContract.methods.largestTimeIncrement().call()
}

export const getLotteryTime = async (lotteryContract) => {
  return lotteryContract.methods.lotteryTime().call()
}

export const getDayTime = async (lotteryContract) => {
  return lotteryContract.methods.dayTime().call()
}

export const getLotteryTimeIncrement = async (lotteryContract) => {
  return lotteryContract.methods.lotteryTimeIncrement().call()
}

export const getDayTimeIncrement = async (lotteryContract) => {
  return lotteryContract.methods.dayTimeIncrement().call()
}

export const getWeekDay = async (lotteryContract) => {
  return lotteryContract.methods.weekDay().call()
}

export const getNumTicketsTotal = async (lotteryContract, account) => {
  return lotteryContract.methods.numTicketsTotal(account).call()
}

export const getNumTicketsDay = async (lotteryContract, account) => {
  return lotteryContract.methods.numTicketsDay(account).call()
}

export const getNumDepositTicketsRemaining = async (lotteryContract, account) => {
  return lotteryContract.methods.numDepositTicketsRemaining(account).call()
}

export const getNumCompoundTicketsRemaining = async (lotteryContract, account) => {
  return lotteryContract.methods.numRollTicketsRemaining(account).call()
}

export const getContractInfoTotals = async (lotteryContract) => {
  return lotteryContract.methods.contractInfo().call()
}

export const getUserInfoTotals = async (lotteryContract, account) => {
  return lotteryContract.methods.userInfoTotals(account).call()
}

export const getUserInfo = async (lotteryContract, account) => {
  return lotteryContract.methods.users(account).call()
}

export const getDayDripEstimate = async (lotteryContract, account) => {
  return lotteryContract.methods.getDayDripEstimate(account).call()
}

export const getDistributionRewards = async (lotteryContract, account) => {
  return lotteryContract.methods.getDistributionRewards(account).call()
}

export const getLotteryMin = async (lotteryContract) => {
  return lotteryContract.methods.ticketPrice().call()
}

export const getNumRandQualified = async (lotteryContract) => {
  return lotteryContract.methods.ticketId().call()
}

export const getTotalUsers = async (lotteryContract) => {
  return lotteryContract.methods.total_users().call()
}

export const isLargestQualified = async (lotteryContract, account) => {
  return lotteryContract.methods.isLargestQualified(account).call()
}

export const getLargestRatioRemaining = async (lotteryContract) => {
  return lotteryContract.methods.getLargestRatioRemaining().call()
}

export const getDayDeposits = async (lotteryContract, account) => {
  return lotteryContract.methods.getDayDeposits(account).call()
}

export const getPastRandomWinners = async (lotteryContract, timestamp) => {
  return lotteryContract.methods.listRandomWinners(timestamp).call()
}

export const getLargestQualifiedTotal = async (lotteryContract, timestamp) => {
  return lotteryContract.methods.getLargestQualifiedTotal(timestamp).call()
}

export const getLargestQualified = async (lotteryContract, timestamp) => {
  return lotteryContract.methods.listLargestQualified(timestamp).call()
}

export const getPastLargestDeposits = async (lotteryContract, timestamp) => {
  return lotteryContract.methods.listLargestDeposits(timestamp).call()
}

export const getPastTicketWinners = async (lotteryContract, timestamp) => {
  return lotteryContract.methods.listLargestTicketWinners(timestamp).call()
}

