import { useCallback, useState, useEffect } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import multicall from 'utils/multicall'
import BigNumber from 'bignumber.js'
import { useGlass, useLottery } from 'hooks/useContract'
import useRefresh from './useRefresh'
import { getLargestRatioRemaining, getLargestQualified, getLargestQualifiedTotal, isLargestQualified, getNumCompoundTicketsRemaining, getNumDepositTicketsRemaining, getNumTicketsTotal, getNumTicketsDay, getWeekDay, getLotteryTime, getPastRandomWinners, getContractInfoTotals, getDayDripEstimate, getGlassBalance, getGlassBalancePool, getLargestDayDepositor, getTimeToReward, getTotalDeposited, getTotalRewards, getUserInfo, getUserInfoTotals, getLargestTime, getLotteryMin, getNumRandQualified, getTotalUsers, getDistributionRewards, getLargestTimeIncrement, getLotteryTimeIncrement, getPastLargestDeposits, getPastTicketWinners, getDayDeposits, getDayTime, getDayTimeIncrement } from '../utils/lotteryUtils'

export const useTotalRewards = () => {
  const { account } = useWallet()
  const [rewards, setRewards] = useState(new BigNumber(0))
  const lotteryContract = useLottery()
  const { instantRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getTotalRewards(lotteryContract, account)
      setRewards(new BigNumber(res))
    }

    if (lotteryContract && account) {
      fetchBalance()
    }
  }, [lotteryContract, account, instantRefresh, setRewards])

  return rewards
}

export const usePoolBalance = () => {
  const [poolBalance, setPoolBalance] = useState(new BigNumber(0))
  const lotteryContract = useLottery()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getGlassBalancePool(lotteryContract)
      setPoolBalance(new BigNumber(res))
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [lotteryContract, slowRefresh, setPoolBalance])

  return poolBalance
}

export const useTotalDeposited = () => {
  const [totalDeposited, setTotalDeposited] = useState(new BigNumber(0))
  const lotteryContract = useLottery()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getTotalDeposited(lotteryContract)
      setTotalDeposited(new BigNumber(res))
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [lotteryContract, slowRefresh, setTotalDeposited])

  return totalDeposited
}

export const useGlassBalance = (account) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const glassContract = useGlass()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getGlassBalance(glassContract, account)
      setBalance(new BigNumber(res))
    }

    if (glassContract && account) {
      fetchBalance()
    }
  }, [glassContract, fastRefresh, account, setBalance])

  return balance
}

export const useTimeToReward = () => {
  const [timeToReward, setTimeToReward] = useState('')
  const lotteryContract = useLottery()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getTimeToReward(lotteryContract)
      setTimeToReward(res)
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [lotteryContract, fastRefresh, setTimeToReward])

  return timeToReward
}

export const useLargestTime = (prevRounds = 0) => {
  const [rewardTime, setRewardTime] = useState([] as string[])
  const lotteryContract = useLottery()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const timestamps: string[] = []
      const [time, increment] = await Promise.all([getLargestTime(lotteryContract), prevRounds > 0 ? getLargestTimeIncrement(lotteryContract) : new Promise((resolve) => resolve(0))])
      
      for (let i = 0; i < prevRounds + 1; i++) {
        timestamps[i] = String(+time - (+increment * i))
      }

      setRewardTime(timestamps)
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [fastRefresh, lotteryContract, prevRounds, setRewardTime])

  return rewardTime
}

export const useDayTime = (prevRound = 0) => {
  const [rewardTime, setRewardTime] = useState('')
  const lotteryContract = useLottery()
  const { fastRefresh, slowRefresh } = useRefresh()
  const refresh = prevRound < 2 ? fastRefresh : slowRefresh

  useEffect(() => {
    const fetchBalance = async () => {
      const increment = prevRound > 0 ? await getDayTimeIncrement(lotteryContract) : 0;
      const res = +await getDayTime(lotteryContract) - (+increment * prevRound)
      setRewardTime(String(res))
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [refresh, lotteryContract, prevRound, setRewardTime])

  return rewardTime
}

export const useLotteryTime = () => {
  const [lotteryTime, setLotteryTime] = useState('')
  const lotteryContract = useLottery()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getLotteryTime(lotteryContract)
      setLotteryTime(res)
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [lotteryContract, fastRefresh, setLotteryTime])

  return lotteryTime
}

export const useWeekDay = () => {
  const [weekDay, setWeekDay] = useState('')
  const lotteryContract = useLottery()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getWeekDay(lotteryContract)
      setWeekDay(res)
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [lotteryContract, slowRefresh, setWeekDay])

  return weekDay
}

export const useLotteryMin = () => {
  const [lotteryMin, setLotteryMin] = useState(new BigNumber('0'))
  const lotteryContract = useLottery()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getLotteryMin(lotteryContract)
      setLotteryMin(new BigNumber(res))
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [lotteryContract, setLotteryMin])

  return lotteryMin
}

export const useNumDepositTicketsRemaining = () => {
  const [lotteryMin, setLotteryMin] = useState(new BigNumber('0'))
  const { account } = useWallet()
  const lotteryContract = useLottery()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getNumDepositTicketsRemaining(lotteryContract, account)
      setLotteryMin(new BigNumber(res))
    }

    if (lotteryContract && account) {
      fetchBalance()
    }
  }, [slowRefresh, lotteryContract, setLotteryMin, account])

  return lotteryMin
}

export const useDayDeposits = () => {
  const [dayDeposits, setDayDeposits] = useState(new BigNumber('0'))
  const { account } = useWallet()
  const lotteryContract = useLottery()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getDayDeposits(lotteryContract, account)
      setDayDeposits(new BigNumber(res))
    }

    if (lotteryContract && account) {
      fetchBalance()
    }
  }, [fastRefresh, lotteryContract, setDayDeposits, account])

  return dayDeposits
}

export const useNumCompoundTicketsRemaining = () => {
  const [lotteryMin, setLotteryMin] = useState(new BigNumber('0'))
  const { account } = useWallet()
  const lotteryContract = useLottery()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getNumCompoundTicketsRemaining(lotteryContract, account)
      setLotteryMin(new BigNumber(res))
    }

    if (lotteryContract && account) {
      fetchBalance()
    }
  }, [slowRefresh, lotteryContract, setLotteryMin, account])

  return lotteryMin
}

export const useGetRandQualified = () => {
  const [randQualified, setRandQualified] = useState(new BigNumber('0'))
  const lotteryContract = useLottery()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const res = await getNumRandQualified(lotteryContract)
      setRandQualified(new BigNumber(res))
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [slowRefresh, lotteryContract, setRandQualified])

  return randQualified
}

export const useLargestDayDepositor = () => {
  const [largestDayDepositer, setLargestDayDepositer] = useState({} as any)
  const lotteryContract = useLottery()
  const { instantRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const depositer = await getLargestDayDepositor(lotteryContract)
      setLargestDayDepositer(depositer)
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [instantRefresh, lotteryContract, setLargestDayDepositer])

  return largestDayDepositer
}

export const usePastLargestWinners = (prevRound = 0) => {
  const [largestDayDepositer, setLargestDayDepositer] = useState([])
  const lotteryContract = useLottery()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const [largestTime, largestTimeIncrement] = await Promise.all([getLargestTime(lotteryContract), getLargestTimeIncrement(lotteryContract)])
      const addr = await getLargestQualified(lotteryContract, +largestTime - (+largestTimeIncrement * prevRound))
      setLargestDayDepositer(addr)
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [fastRefresh, lotteryContract, setLargestDayDepositer, prevRound])

  return largestDayDepositer
}

export const useDayDripEstimate = () => {
  const [dayDripEstimate, setDayDripEstimate] = useState(new BigNumber(0))
  const lotteryContract = useLottery()
  const { account } = useWallet()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const deposit = await getDayDripEstimate(lotteryContract, account)
      setDayDripEstimate(new BigNumber(deposit))
    }

    if (lotteryContract && account) {
      fetchBalance()
    }
  }, [fastRefresh, lotteryContract, setDayDripEstimate, account])

  return dayDripEstimate
}

export const useNumTicketsTotal = () => {
  const [numTickets, setNumTickets] = useState(new BigNumber(0))
  const lotteryContract = useLottery()
  const { account } = useWallet()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const rewards = await getNumTicketsTotal(lotteryContract, account)
      setNumTickets(new BigNumber(rewards))
    }

    if (lotteryContract && account) {
      fetchBalance()
    }
  }, [fastRefresh, lotteryContract, setNumTickets, account])

  return numTickets
}

export const useNumTicketsDay = () => {
  const [numTickets, setNumTickets] = useState(new BigNumber(0))
  const lotteryContract = useLottery()
  const { account } = useWallet()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const rewards = await getNumTicketsDay(lotteryContract, account)
      setNumTickets(new BigNumber(rewards))
    }

    if (lotteryContract && account) {
      fetchBalance()
    }
  }, [fastRefresh, lotteryContract, setNumTickets, account])

  return numTickets
}

export const useGetContractInfo = () => {
  const [contractInfo, setContractInfo] = useState({} as any)
  const lotteryContract = useLottery()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const info = await getContractInfoTotals(lotteryContract)
      setContractInfo(info)
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [slowRefresh, lotteryContract, setContractInfo])

  return contractInfo
}

export const useGetUserInfoTotals = () => {
  const [userInfoTotals, setUserInfoTotals] = useState({} as any)
  const lotteryContract = useLottery()
  const { account } = useWallet()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const info = await getUserInfoTotals(lotteryContract, account)
      setUserInfoTotals(info)
    }

    if (lotteryContract && account) {
      fetchBalance()
    }
  }, [fastRefresh, lotteryContract, setUserInfoTotals, account])

  return userInfoTotals
}

export const useGetUserInfo = () => {
  const [userInfo, setUserInfo] = useState({} as any)
  const lotteryContract = useLottery()
  const { account } = useWallet()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const info = await getUserInfo(lotteryContract, account)
      setUserInfo(info)
    }

    if (lotteryContract && account) {
      fetchBalance()
    }
  }, [fastRefresh, lotteryContract, setUserInfo, account])

  return userInfo
}

export const usePastRandomWinners = (prevRound = 1) => {
  const [pastRandomWinner, setPastRandomWinner] = useState([])
  const lotteryContract = useLottery()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const [lotteryTime, lotteryTimeIncrement] = await Promise.all([getLotteryTime(lotteryContract), getLotteryTimeIncrement(lotteryContract)])
      const info = await getPastRandomWinners(lotteryContract, +lotteryTime - (+lotteryTimeIncrement * prevRound))
      setPastRandomWinner(info)
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [slowRefresh, lotteryContract, setPastRandomWinner, prevRound])

  return pastRandomWinner
}

export const useIsLargestQualified = () => {
  const [largestQualified, setLargestQualified] = useState(false)
  const lotteryContract = useLottery()
  const { account } = useWallet()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const info = await isLargestQualified(lotteryContract, account)
      setLargestQualified(info)
    }

    if (lotteryContract && account) {
      fetchBalance()
    }
  }, [fastRefresh, lotteryContract, account, setLargestQualified])

  return largestQualified
}

export const useLargestRatioRemaining = () => {
  const [isRatio, setRatio] = useState(new BigNumber(0))
  const lotteryContract = useLottery()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const info = await getLargestRatioRemaining(lotteryContract)
      setRatio(new BigNumber(info))
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [fastRefresh, lotteryContract, setRatio])

  return isRatio
}

export const useLargestQualifiedTotal = (prevRounds = 0) => {
  const [largestQualified, setLargestQualified] = useState([new BigNumber(0)])
  const lotteryContract = useLottery()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetchBalance = async () => {
      const calls = []
      const [largestTime, largestTimeIncrement] = await Promise.all([getLargestTime(lotteryContract), getLargestTimeIncrement(lotteryContract)])

      for (let i = 0; i < prevRounds + 1; i++) {
        const timestamp = +largestTime - (+largestTimeIncrement * i)
        calls.push({
          address: lotteryContract.options.address,
          name: 'getLargestQualifiedTotal',
          params: [timestamp],
        })
      }
      const data = await multicall(lotteryContract.options.jsonInterface, calls)
      setLargestQualified(data)
    }

    if (lotteryContract) {
      fetchBalance()
    }
  }, [fastRefresh, lotteryContract, setLargestQualified, prevRounds])

  return largestQualified
}

export const useLargestQualifiedUsers = (prevRounds = 0) => {
  const [users, setUsers] = useState([] as any[])
  const lotteryContract = useLottery()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    const fetch = async () => {
      const calls = []
      const [largestTime, largestTimeIncrement] = await Promise.all([getLargestTime(lotteryContract), getLargestTimeIncrement(lotteryContract)])

      for (let i = 0; i < prevRounds + 1; i++) {
        calls.push({
          address: lotteryContract.options.address,
          name: 'listLargestQualified',
          params: [+largestTime - (+largestTimeIncrement * i)],
        })
      }

      for (let i = 0; i < prevRounds + 1; i++) {
        calls.push({
          address: lotteryContract.options.address,
          name: 'listLargestDeposits',
          params: [+largestTime - (+largestTimeIncrement * i)],
        })
      }

      for (let i = 0; i < prevRounds + 1; i++) {
        calls.push({
          address: lotteryContract.options.address,
          name: 'listLargestTicketWinners',
          params: [+largestTime - (+largestTimeIncrement * i)],
        })
      }

      const payload = await multicall(lotteryContract.options.jsonInterface, calls)      
      if (!payload) return setUsers([])

      const addresses = []
      const amounts = []
      const tickets = []

      for (let i = 0; i < payload.length; i++) {
        if (i < prevRounds + 1) {
          addresses.push(payload[i])
        } else if (i < (prevRounds + 1) * 2) {
          amounts.push(payload[i])
        } else {
          tickets.push(payload[i])
        }
      }         

      const data = []

      for (let i = 0; i < addresses.length; i++) {
        data[i] = []

        for (let j = 0; j < addresses[i][0].length; j++) {

          if (addresses[i][0][j].length > 0) {

            let ticketsWon = 0

            for (let k = 0; k < tickets[i][0].length; k++) {
              if (addresses[i][0][j].toLowerCase() === tickets[i][0][k].toLowerCase()) {
                ticketsWon++;
              }
            }

            data[i][j] = {
              address: addresses[i][0][j], 
              day_deposits: amounts[i][0][j], 
              tickets_won: ticketsWon
            }
          }
        }

        data[i] = data[i].sort((u1, u2) => {
          if (+u1.day_deposits > +u2.day_deposits)
            return -1;
          if (+u2.day_deposits > +u1.day_deposits)
            return 1;
          return 0;
        });
      }

      return setUsers(data)
    }

    if (lotteryContract) {
      fetch()
    }
  }, [fastRefresh, lotteryContract, setUsers, prevRounds])

  return users
}

export const useSortedUsers = () => {
  const [users, setUsers] = useState([] as any[])
  const lotteryContract = useLottery()

  useEffect(() => {
    const fetch = async () => {
      let calls = []
      const numUsers = +await getTotalUsers(lotteryContract);
      if (!numUsers) return setUsers([]);

      for (let i = 0; i < numUsers; i++) {
        calls.push({
          address: lotteryContract.options.address,
          name: 'userIndices',
          params: [i],
        });
      }

      const userAdresses = await multicall(lotteryContract.options.jsonInterface, calls);
      if (!userAdresses) return setUsers([]);
      calls = [];

      for (let i = 0; i < userAdresses.length; i++) {
        calls.push({
          address: lotteryContract.options.address,
          name: 'userInfoTotals',
          params: userAdresses[i],
        });
      }

      const userTotals = await multicall(lotteryContract.options.jsonInterface, calls);
      if (!userTotals) return setUsers([]);

      for (let i = 0; i < userTotals.length; i++) {
        userTotals[i] = { address: calls[i].params[0], ...userTotals[i] };
      }

      const sortedUsers = userTotals.sort((u1, u2) => {
        if (u1.total_deposits.gt(u2.total_deposits))
          return -1;
        if (u2.total_deposits.gt(u1.total_deposits))
          return 1;
        return 0;
      });

      return setUsers(sortedUsers)
    }

    if (lotteryContract) {
      fetch()
    }
  }, [lotteryContract, setUsers])

  return users
}
