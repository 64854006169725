import contracts from './contracts'
import { FarmConfig, QuoteToken, } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'GLASS-BUSD LP',
    risk: 5,
    lpAddresses: {
      56: '0xf812F3332Bb49f70DCBca705eeC2584257091Dd9',
      97: '0xfd5223145ceb311cc4ecf64012232f55f21828ac',
    },
    tokenSymbol: 'GLASS',
    tokenAddresses: {
      56: '0xdDa8B8e973060215d1aEe3839189cD42eAea8230',
      97: '0x67BA81722f5c8cbCd1CFB89BC93d8b184232a5cA'
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    lpSymbol: 'BNB-BUSD LP',
    risk: 5,
    lpAddresses: {
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
      97: '0xfd5223145ceb311cc4ecf64012232f55f21828ac',

    },
    tokenSymbol: 'WBNB',
    tokenAddresses: {
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      97: '0x094616f0bdfb0b526bd735bf66eca0ad254ca81f',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  // {
  //   pid: 11,
  //   isCommunity: true,
  //   risk: 5,
  //   lpSymbol: 'GLASS-miMATIC LP',
  //   lpAddresses: {
  //     137: '0x4261c90f01e28c67ed9c3dc11750c19fda5af89a', // GLASS-miMATIC LP
  //   },
  //   tokenSymbol: 'GLASS',
  //   tokenAddresses: {
  //     137: '0xe118e8b6dc166CD83695825eB1d30e792435Bb00',
  //   },
  //   quoteTokenSymbol: QuoteToken.miMATIC,
  //   quoteTokenAdresses: contracts.mimatic,
  // },
  // {
  //   pid: 12,
  //   risk: 5,
  //   lpSymbol: 'miMATIC-USDC LP',
  //   lpAddresses: {
  //     137: '0x160532d2536175d65c03b97b0630a9802c274dad', // miMATIC-USDC LP
  //   },
  //   tokenSymbol: 'miMATIC',
  //   tokenAddresses: {
  //     137: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
  //   },
  //   quoteTokenSymbol: QuoteToken.USDC,
  //   quoteTokenAdresses: contracts.usdc,
  // },
  // {
  //   pid: 1,
  //   risk: 5,
  //   isTokenOnly: true,
  //   lpSymbol: 'GLASS',
  //   lpAddresses: {
  //     137: '0xbDe5A832760A4C126eEC959ec825D37DC6872064', // GLASS-USDC LP
  //   },
  //   tokenSymbol: 'GLASS',
  //   tokenAddresses: {
  //     137: '0xe118e8b6dc166CD83695825eB1d30e792435Bb00',
  //   },
  //   quoteTokenSymbol: QuoteToken.USDC,
  //   quoteTokenAdresses: contracts.usdc,
  // },
  // {
  //   pid: 2,
  //   risk: 5,
  //   lpSymbol: 'GLASS-MATIC LP',
  //   lpAddresses: {
  //     137: '0xD58190075d5C32567940A766FDC7BF5e95679Df5', // GLASS-WMATIC LP
  //   },
  //   tokenSymbol: 'GLASS',
  //   tokenAddresses: {
  //     137: '0xe118e8b6dc166CD83695825eB1d30e792435Bb00',
  //   },
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   quoteTokenAdresses: contracts.wmatic,
  // },
  // {
  //   pid: 3,
  //   risk: 5,
  //   lpSymbol: 'WMATIC-USDC LP',
  //   lpAddresses: {
  //     137: '0x6e7a5FAFcec6BB1e78bAE2A1F0B612012BF14827', // USDC-WMATIC LP
  //   },
  //   tokenSymbol: 'MATIC',
  //   tokenAddresses: {
  //     137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
  //   },
  //   quoteTokenSymbol: QuoteToken.USDC,
  //   quoteTokenAdresses: contracts.usdc,
  // },
  // {
  //   pid: 13,
  //   risk: 5,
  //   lpSymbol: 'GLASS-HEX LP',
  //   lpAddresses: {
  //     137: '0xc4451fbd6dbce2204c96434696a23540caeb3c67', // GLASS-HEX LP
  //   },
  //   tokenSymbol: 'GLASS',
  //   tokenAddresses: {
  //     137: '0xe118e8b6dc166CD83695825eB1d30e792435Bb00',
  //   },
  //   quoteTokenSymbol: QuoteToken.HEX,
  //   quoteTokenAdresses: contracts.hex,
  // },
  // {
  //   pid: 14,
  //   risk: 5,
  //   lpSymbol: 'HEX-USDC LP',
  //   lpAddresses: {
  //     137: '0x149bcbaf01034b481deb6f0dbf58a1ef79b2df04', // HEX-USDC LP
  //   },
  //   tokenSymbol: 'HEX',
  //   tokenAddresses: {
  //     137: '0x23D29D30e35C5e8D321e1dc9A8a61BFD846D4C5C',
  //   },
  //   quoteTokenSymbol: QuoteToken.USDC,
  //   quoteTokenAdresses: contracts.usdc,
  // },
  // {
  //   pid: 4,
  //   risk: 5,
  //   lpSymbol: 'GLASS-ETH LP',
  //   lpAddresses: {
  //     137: '0x40A8772A6C917569d28A136A458E3051B96b4AC3', // GLASS-WETH LP
  //   },
  //   tokenSymbol: 'GLASS',
  //   tokenAddresses: {
  //     137: '0xe118e8b6dc166CD83695825eB1d30e792435Bb00',
  //   },
  //   quoteTokenSymbol: QuoteToken.ETH,
  //   quoteTokenAdresses: contracts.weth,
  // },
  // {
  //   pid: 5,
  //   risk: 5,
  //   lpSymbol: 'ETH-USDC LP',
  //   lpAddresses: {
  //     137: '0x853ee4b2a13f8a742d64c8f088be7ba2131f670d', // GLASS-WETH LP
  //   },
  //   tokenSymbol: 'WETH',
  //   tokenAddresses: {
  //     137: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
  //   },
  //   quoteTokenSymbol: QuoteToken.USDC,
  //   quoteTokenAdresses: contracts.usdc,
  // },
  // {
  //   pid: 6,
  //   risk: 5,
  //   lpSymbol: 'GLASS-SRAT LP',
  //   lpAddresses: {
  //     137: '0xdd31Ec2EBA3AA682dF8a3BE038920e32505B243D', // GLASS-WETH LP
  //   },
  //   tokenSymbol: 'GLASS',
  //   tokenAddresses: {
  //     137: '0xe118e8b6dc166CD83695825eB1d30e792435Bb00',
  //   },
  //   quoteTokenSymbol: QuoteToken.SRAT,
  //   quoteTokenAdresses: contracts.srat,
  // },
  // {
  //   pid: 7,
  //   risk: 5,
  //   lpSymbol: 'SRAT-WMATIC LP',
  //   lpAddresses: {
  //     137: '0x64958654412fccad8b46e68c49075bf6d64cdad7', // SRAT-WMATIC LP
  //   },
  //   tokenSymbol: 'WMATIC',
  //   tokenAddresses: {
  //     137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  //   },
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   quoteTokenAdresses: contracts.wmatic,
  // },
  // {
  //   pid: 8,
  //   risk: 5,
  //   lpSymbol: 'GLASS-PRISM LP',
  //   lpAddresses: {
  //     137: '0xfdbc136002d51686c5455b5343f49cc90be9fac3', // GLASS-pPRISM LP
  //   },
  //   tokenSymbol: 'GLASS',
  //   tokenAddresses: {
  //     137: '0xe118e8b6dc166CD83695825eB1d30e792435Bb00',
  //   },
  //   quoteTokenSymbol: QuoteToken.PRISM,
  //   quoteTokenAdresses: contracts.prism,
  // },
  // {
  //   pid: 9,
  //   risk: 5,
  //   lpSymbol: 'PRISM-WMATIC LP',
  //   lpAddresses: {
  //     137: '0xe6d25422575bcb5d81d47ecfdcca0da4e07336ae', // pPRISM-WMATIC LP
  //   },
  //   tokenSymbol: 'pPRISM',
  //   tokenAddresses: {
  //     137: '0xd46df541148932690b81092f600f35208afd4325',
  //   },
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   quoteTokenAdresses: contracts.wmatic,
  // },
  // {
  //   pid: 10,
  //   risk: 5,
  //   lpSymbol: 'PRISM-USDC LP',
  //   lpAddresses: {
  //     137: '0xd71100ca84df1384583d32d4d71d2e2f7ff8973e', // pPRISM-WMATIC LP
  //   },
  //   tokenSymbol: 'pPRISM',
  //   tokenAddresses: {
  //     137: '0xd46df541148932690b81092f600f35208afd4325',
  //   },
  //   quoteTokenSymbol: QuoteToken.USDC,
  //   quoteTokenAdresses: contracts.usdc,
  // },
  // {
  //   pid: 2,
  //   risk: 3,
  //   lpSymbol: 'MATIC-USDC LP',
  //   lpAddresses: {
  //     137: '0x6e7a5fafcec6bb1e78bae2a1f0b612012bf14827',
  //     56: '0xd1b59d11316e87c3a0a069e80f590ba35cd8d8d3',
  //   },
  //   tokenSymbol: 'MATIC',
  //   tokenAddresses: {
  //     137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
  //     56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
  //   },
  //   quoteTokenSymbol: QuoteToken.USDC,
  //   quoteTokenAdresses: contracts.usdc,
  // },
  // {
  //   pid: 2,
  //   risk: 3,
  //   lpSymbol: 'MATIC-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
  //   },
  //   tokenSymbol: 'MATIC',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 3,
  //   risk: 1,
  //   lpSymbol: 'USDT-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xc15fa3e22c912a276550f3e5fe3b0deb87b55acd',
  //   },
  //   tokenSymbol: 'USDT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x55d398326f99059ff775485246999027b3197955',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 4,
  //   risk: 2,
  //   lpSymbol: 'BTCB-MATIC LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x7561eee90e24f3b348e1087a005f78b4c8453524',
  //   },
  //   tokenSymbol: 'BTCB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   },
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   quoteTokenAdresses: contracts.wmatic,
  // },
  // {
  //   pid: 5,
  //   risk: 2,
  //   lpSymbol: 'ETH-MATIC LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x70d8929d04b60af4fb9b58713ebcf18765ade422',
  //   },
  //   tokenSymbol: 'ETH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   },
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   quoteTokenAdresses: contracts.wmatic,
  // },
  // {
  //   pid: 6,
  //   risk: 1,
  //   lpSymbol: 'DAI-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x3ab77e40340ab084c3e23be8e5a6f7afed9d41dc',
  //   },
  //   tokenSymbol: 'DAI',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 7,
  //   risk: 1,
  //   lpSymbol: 'USDC-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x680dd100e4b394bda26a59dd5c119a391e747d18',
  //   },
  //   tokenSymbol: 'USDC',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 9,
  //   risk: 3,
  //   lpSymbol: 'DOT-MATIC LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xbcd62661a6b1ded703585d3af7d7649ef4dcdb5c',
  //   },
  //   tokenSymbol: 'DOT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  //   },
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   quoteTokenAdresses: contracts.wmatic,
  // },
  // {
  //   pid: 10,
  //   risk: 4,
  //   lpSymbol: 'CAKE-BUSD LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x0ed8e0a2d99643e1e65cca22ed4424090b8b7458',
  //   },
  //   tokenSymbol: 'CAKE',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 11,
  //   risk: 4,
  //   lpSymbol: 'CAKE-MATIC LP',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xa527a61703d82139f8a06bc30097cc9caa2df5a6',
  //   },
  //   tokenSymbol: 'CAKE',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   },
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   quoteTokenAdresses: contracts.wmatic,
  // },
  // {
  //   pid: 12,
  //   risk: 5,
  //   isTokenOnly: true,
  //   lpSymbol: 'GLASS',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019', // GLASS-BUSD LP
  //   },
  //   tokenSymbol: 'GLASS',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 13,
  //   risk: 1,
  //   isTokenOnly: true,
  //   lpSymbol: 'BUSD',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x19e7cbecdd23a16dfa5573df54d98f7caae03019', // GLASS-BUSD LP (BUSD-BUSD will ignore)
  //   },
  //   tokenSymbol: 'BUSD',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 14,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'WMATIC',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f', // MATIC-BUSD LP
  //   },
  //   tokenSymbol: 'WMATIC',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 15,
  //   risk: 1,
  //   isTokenOnly: true,
  //   lpSymbol: 'USDT',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xc15fa3e22c912a276550f3e5fe3b0deb87b55acd', // USDT-BUSD LP
  //   },
  //   tokenSymbol: 'USDT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x55d398326f99059ff775485246999027b3197955',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 16,
  //   risk: 2,
  //   isTokenOnly: true,
  //   lpSymbol: 'BTCB',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xb8875e207ee8096a929d543c9981c9586992eacb', // BTCB-BUSD LP
  //   },
  //   tokenSymbol: 'BTCB',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 17,
  //   risk: 2,
  //   isTokenOnly: true,
  //   lpSymbol: 'ETH',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xd9a0d1f5e02de2403f68bb71a15f8847a854b494', // ETH-BUSD LP
  //   },
  //   tokenSymbol: 'ETH',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 18,
  //   risk: 1,
  //   isTokenOnly: true,
  //   lpSymbol: 'DAI',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x3ab77e40340ab084c3e23be8e5a6f7afed9d41dc', // DAI-BUSD LP
  //   },
  //   tokenSymbol: 'DAI',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 19,
  //   risk: 1,
  //   isTokenOnly: true,
  //   lpSymbol: 'USDC',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x680dd100e4b394bda26a59dd5c119a391e747d18', // USDC-BUSD LP
  //   },
  //   tokenSymbol: 'USDC',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 20,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'DOT',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x54c1ec2f543966953f2f7564692606ea7d5a184e', // DOT-BUSD LP
  //   },
  //   tokenSymbol: 'DOT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 21,
  //   risk: 4,
  //   isTokenOnly: true,
  //   lpSymbol: 'CAKE',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x0ed8e0a2d99643e1e65cca22ed4424090b8b7458', // CAKE-BUSD LP
  //   },
  //   tokenSymbol: 'CAKE',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 22,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'BSCX',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xa32a983a64ce21834221aa0ad1f1533907553136', // BSCX-BUSD LP
  //   },
  //   tokenSymbol: 'BSCX',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x5ac52ee5b2a633895292ff6d8a89bb9190451587',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 23,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'AUTO',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x4d0228ebeb39f6d2f29ba528e2d15fc9121ead56', // AUTO-MATIC LP
  //   },
  //   tokenSymbol: 'AUTO',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa184088a740c695e156f91f5cc086a06bb78b827',
  //   },
  //   quoteTokenSymbol: QuoteToken.MATIC,
  //   quoteTokenAdresses: contracts.wmatic,
  // },
]

export default farms
