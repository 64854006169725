import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  // {
  //   sousId: 0,
  //   tokenName: 'GLASS',
  //   stakingTokenName: QuoteToken.GLASS,
  //   stakingTokenAddress: '0xe118e8b6dc166CD83695825eB1d30e792435Bb00',
  //   contractAddress: {
  //     137: '0xCEd39CF6221a10331e2349224BB1Eeb03A5c146f',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: '',
  //   harvest: true,
  //   tokenPerBlock: '1',
  //   sortOrder: 1,
  //   isFinished: false,
  //   tokenDecimals: 9,
  // },
  // {
  //   sousId: 1,
  //   tokenName: 'MATIC',
  //   stakingTokenName: QuoteToken.MATIC,
  //   stakingTokenAddress: '0x0000000000000000000000000000000000000000',
  //   contractAddress: {
  //     137: '0xCEd39CF6221a10331e2349224BB1Eeb03A5c146f',
  //   },
  //   poolCategory: PoolCategory.MATIC,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 2,
  //   tokenName: 'pPRSIM',
  //   stakingTokenName: QuoteToken.PRISM,
  //   stakingTokenAddress: '0xd46df541148932690b81092f600f35208afd4325',
  //   contractAddress: {
  //     137: '0xCEd39CF6221a10331e2349224BB1Eeb03A5c146f',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 3,
  //   tokenName: 'miMATIC',
  //   stakingTokenName: QuoteToken.miMATIC,
  //   stakingTokenAddress: '0xa3fa99a148fa48d14ed51d610c367c61876997f1',
  //   contractAddress: {
  //     137: '0xCEd39CF6221a10331e2349224BB1Eeb03A5c146f',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
  // {
  //   sousId: 4,
  //   tokenName: 'HEX',
  //   stakingTokenName: QuoteToken.HEX,
  //   stakingTokenAddress: '0x23D29D30e35C5e8D321e1dc9A8a61BFD846D4C5C',
  //   contractAddress: {
  //     137: '0xCEd39CF6221a10331e2349224BB1Eeb03A5c146f',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   projectLink: 'https://trustwallet.com/',
  //   harvest: true,
  //   tokenPerBlock: '20',
  //   sortOrder: 999,
  //   isFinished: true,
  //   tokenDecimals: 18,
  // },
]

export default pools
